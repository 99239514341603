import { Layouts } from '../components/layouts/layout';
import AddIcon from '../res/icons/ic_add.svg'

import '../assets/styles/pages/home.scss';
import MenuContext from '../components/home/MenuContext';
import {ReactElement, useState } from 'react';
import CameraTile from '../components/home/CameraTile';
import AudioTile from '../components/home/AudioTile';
import ScreenTile from '../components/home/ScreenTile';

export const HomePage = (): ReactElement => {

  const [isVisible, setIsVisible] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [showScreen, setShowScreen] = useState(false);

  const ShowMenu = (): void => {
    setIsVisible((prev) => {
      return !prev;
    });
  }

  function handleCamera () {
    setShowCamera(true);
};

function handleAudio () {
  setShowAudio(true);
};

function handleScreen () {
  setShowScreen(true);
};

  return (
    <Layouts>
        <div className="center">
          <div className="welcome">
          <h1>Capture Sources</h1>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</p>
            <button className="addsrc" onClick={ShowMenu}>
              <img src={AddIcon}/>
              <label>Add Capture Source...</label>
              { isVisible ? <MenuContext handleCamera={handleCamera} handleAudio={handleAudio} handleScreen={handleScreen}/> : null }
            </button>
          </div>
          <br/>
          {showCamera ? <CameraTile/> : null}
          {showAudio ? <AudioTile/> : null}
          {showScreen ? <ScreenTile/> : null}
        </div>
    </Layouts>
  );
};
