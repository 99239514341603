import '../../assets/styles/components/menuContext.scss';

import WebcamIcon from '../../res/icons/ic_webcam.svg'
import ScreenIcon from '../../res/icons/ic_screen.svg'
import MicrophoneIcon from '../../res/icons/ic_mic.svg'
import { ReactElement } from 'react';

const MenuContext = ({ handleCamera, handleAudio, handleScreen }): ReactElement => {

    const WebcamClick = () => {
        handleCamera();
    };

    const ScreenClick = () => {
        handleScreen();
    };

    const MicrophoneClick = () => {
        handleAudio();
    };

    return (
        <div className='contextMenu'>
            <div className='contextEntry' onClick={WebcamClick}>
                <img src={WebcamIcon} />
                <label>Webcam...</label>
            </div>
            <div className='contextEntry' onClick={ScreenClick}>
                <img src={ScreenIcon} />
                <label>Screen...</label>
            </div>
            <div className='contextEntry' onClick={MicrophoneClick}>
                <img src={MicrophoneIcon} />
                <label>Microphone...</label>
            </div>
        </div>
    );
};

export default MenuContext;