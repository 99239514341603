import '../../assets/styles/components/audioTile.scss';
import RecordIcon from '../../res/icons/ic_record.svg'
import StopIcon from '../../res/icons/ic_stop.svg'
import {InputDevice, getAudioInputDevicesAsync} from '@magix.hub/capture-engine'
import {ReactElement, useRef, useState, useEffect} from 'react';

const AudioTile = (): ReactElement => {

    const recordingAudioRef = useRef<HTMLAudioElement>(null);

    const [audioInputDevices, setAudioInputDevices] = useState<InputDevice[]>([])
    const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<InputDevice>()
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const [isReady, setIsReady] = useState<boolean>(false)

    useEffect(() => {
        async function getData() {
            try {
                let res = await getAudioInputDevicesAsync()
                res.forEach((x) => console.log('test', x));
                setAudioInputDevices(res);
            } finally {
                setIsReady(true);
            }
        }

        getData();
        onChangeHandler();
    }, []);

    useEffect(() => {
        async function fetchStream() {
            try {
                await startPlaybackAsync();
            } catch (e) {
                console.error(e);
            }
        };
        fetchStream();
    }, [isReady]);

    const onChangeHandler = () => {
        const selectedInputDevice = audioInputDevices[1];

        if (!selectedInputDevice) {
            return;
        }

        setSelectedAudioInputDevice(selectedInputDevice);
    }

    const startPlaybackAsync = async () => {

        const audioConstraints: MediaStreamConstraints = {
            audio: {
                deviceId: selectedAudioInputDevice?.id
            }
        }

        let audioStream = new MediaStream();

        try {
            audioStream = await window.navigator.mediaDevices.getUserMedia(audioConstraints);
        } catch (error) {
            console.error("Error acquiring camera source:", error);
        }


        if (!recordingAudioRef.current) {
            return;
        }
     
        recordingAudioRef.current.srcObject = audioStream;
    }

    const startRecordingAsync = async () => {
        
        // @ts-ignore
        recordingAudioRef.current = null;
        setIsRecording(true);
        return;
    }

    const stopRecordingAsync = async () => {

        // @ts-ignore
        recordingAudioRef.current = null;
        setIsRecording(false);
        return;
    }

    return (
        isReady ?
            <div className='cameraTile'>
                <audio autoPlay ref={recordingAudioRef} className='audio'/>
                <div className='tileGrid'>
                    <div className='tileLabels'>
                        <label>{selectedAudioInputDevice != null ? selectedAudioInputDevice.name : "Audio Device"}</label>
                        <label>{selectedAudioInputDevice?.capabilities?.sampleRate?.max?.toString()}</label>
                    </div>
                    <div className='tileButtons'>
                        {isRecording ? <button onClick={stopRecordingAsync} className='stop'>
                            <img src={StopIcon}/> Stop
                        </button> : <button onClick={startRecordingAsync} className='rec'>
                            <img src={RecordIcon}/>Rec
                        </button>}
                    </div>
                </div>
            </div> :
            <div/>
    );
};

export default AudioTile;
