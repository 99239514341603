import '../../assets/styles/components/cameraTile.scss';
import { CaptureManager, DisplaySurface } from '@magix.hub/capture-engine'
import { ReactElement, useRef, useState, useEffect } from 'react';
import RecordIcon from '../../res/icons/ic_record.svg'
import StopIcon from '../../res/icons/ic_stop.svg'

const ScreenTile = (): ReactElement => {

    const [captureManager, setCaptureManager] = useState<CaptureManager>()
    const [recordToken, setRecordToken] = useState<string>()
    const [isRecording, setIsRecording] = useState<boolean>()
    const recordingVideoRef = useRef<HTMLVideoElement>(null);
    const [captureStream, setCaptureStream] = useState<MediaStream>()
    const [isReady, setIsReady] = useState<boolean>(false)

    useEffect(() => {     
        setCaptureManager(new CaptureManager());
        async function fetchStream() {
            try {
                await startStreamAsync();
            } catch (e) {
                console.error(e);
            }
        };
        fetchStream();
        setIsReady(true);
    }, []);

    useEffect(() => {
        if (!recordingVideoRef.current || !captureStream) {
            console.log(recordingVideoRef, captureStream);
            return;
        }
        recordingVideoRef.current.srcObject = captureStream;
    }, [captureStream]);
    const startStreamAsync = async () => {


        const displayMediaOptions: DisplayMediaStreamOptions = {
            video: {
                displaySurface: DisplaySurface.Monitor,
            }
        };

        setCaptureStream(new MediaStream());

        try {
            setCaptureStream(await window.navigator.mediaDevices.getDisplayMedia(displayMediaOptions));
        } catch (error) {
            console.error("Error acquiring camera source:", error);
        }
    }

    const startRecordingAsync = async () => {

        const fileHandle = await window.showSaveFilePicker({
            suggestedName: 'myVideo.mp4',
            types: [{
                description: 'Video File',
                accept: { 'video/mp4': ['.mp4'] }
            }],
        });

        if (!captureStream){
            return;
        }
        const videoTrack = captureStream.getVideoTracks()[0];

        if (!videoTrack) {
            return;
        }

        const videoTrackSettings = videoTrack.getSettings();

        const videoConfig: VideoEncoderConfig = {
            codec: 'avc1.640033',
            width: videoTrackSettings?.width ?? 0,
            height: videoTrackSettings?.height ?? 0,
            bitrate: 4 * 10e6,
            framerate: videoTrackSettings.frameRate,
        };


        let audioTrack = captureStream.getAudioTracks()[0];
        console.log(captureStream.getAudioTracks());
        if (audioTrack) {
            let audioTrackSettings = audioTrack.getSettings();

            const audioConfig: AudioEncoderConfig = {
                codec: 'mp4a.40.02',
                sampleRate: audioTrackSettings.sampleRate ?? 0,
                numberOfChannels: audioTrackSettings.channelCount ?? 0,
                bitrate: 128000
            };

            setRecordToken(await captureManager?.startRecordAudioAndVideoAsync(fileHandle, captureStream, videoConfig, captureStream, audioConfig));
            setIsRecording(true);
            return;
        }

        setRecordToken(await captureManager?.startRecordVideoAsync(fileHandle, captureStream, videoConfig));

        setIsRecording(true);

        return;
    }

        const stopRecordingAsync = async () => {
            await captureManager?.stopRecordingAsync(recordToken ?? "");

            setIsRecording(false);

            return;
        }

        return (
            isReady ?
                <div className='cameraTile'>
                    <video autoPlay ref={recordingVideoRef} className='video' />
                    <div className='tileGrid'>
                        <div className='tileLabels'>
                            <label>{"Screen Capture"}</label>
                            <label>{recordingVideoRef.current?.videoWidth}x{recordingVideoRef.current?.videoHeight}</label>
                        </div>
                        <div className='tileButtons'>
                            {isRecording ? <button onClick={stopRecordingAsync} className='stop'>
                                <img src={StopIcon} /> Stop
                            </button> : <button onClick={startRecordingAsync} className='rec'>
                                <img src={RecordIcon} />Rec
                            </button>}
                        </div>
                    </div>
                </div> :
                <div />
        );
    };

    export default ScreenTile;
