import {useState, useEffect, createContext, ReactElement} from 'react';
import {DeviceAccessState} from "../types/DeviceAccessState";

import {
    PermissionStatus,
    queryAudioDevicePermissionsAsync,
    queryVideoDevicePermissionsAsync,
    requestDevicePermissionsAsync
} from "@magix.hub/capture-engine";

const defaultPermissions: DeviceAccessState = {
    videoPermissionsStatus: PermissionStatus.Undefined,
    audioPermissionStatus: PermissionStatus.Undefined
};

const DeviceAccessContext = createContext<null>(null);

export const DeviceAccessProvider = ({children}: any) => {

    const [deviceAccessState, setDeviceAccessState] = useState<DeviceAccessState>(defaultPermissions);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkDeviceAccessAsync();

        async function AskPermissions() {
            await requestDevicePermissionsAsync();
            await checkDeviceAccessAsync();
        }

        AskPermissions();
    }, []);


    const checkDeviceAccessAsync = async (): Promise<void> => {
        setLoading(true);

        await checkVideoDeviceAccessAsync();

        await checkAudioDeviceAccessAsync();

        setLoading(false);
    };

    const checkVideoDeviceAccessAsync = async () => {
        const videoDevicePermissionsStatus = await queryVideoDevicePermissionsAsync();

        setDeviceAccessState((prev) => {
            return {...prev, videoPermissionsStatus: videoDevicePermissionsStatus}
        });
    }

    const checkAudioDeviceAccessAsync = async () => {
        const audioDevicePermissionsStatus = await queryAudioDevicePermissionsAsync();

        setDeviceAccessState((prev) => {
            return {...prev, audioPermissionStatus: audioDevicePermissionsStatus}
        });
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    const RequestDeviceAccess = (): ReactElement => {

        const RequestVideoAndAudioDeviceAccessAsync = async () => {
            await requestDevicePermissionsAsync();
            await checkDeviceAccessAsync();
        }

        return (
            <div>
                <div>Microphone state - {deviceAccessState.audioPermissionStatus.toString()}</div>
                <div>Video state - {deviceAccessState.videoPermissionsStatus.toString()}</div>
                <div>
                    <button onClick={RequestVideoAndAudioDeviceAccessAsync}>Allow necessary permissions</button>
                </div>
            </div>
        );
    };

    if (deviceAccessState.videoPermissionsStatus != PermissionStatus.Granted || deviceAccessState.audioPermissionStatus != PermissionStatus.Granted) {
        return <RequestDeviceAccess/>;
    }

    return (
        <DeviceAccessContext.Provider value={null}>{children}</DeviceAccessContext.Provider>
    );
};