import '../../assets/styles/components/cameraTile.scss';
import { CaptureManager, InputDevice, getVideoInputDevicesAsync, getValidatedVideoCodecConfigurationsAsync, getSuggestedBitRates, getVideoDeviceOperatingPoints } from '@magix.hub/capture-engine'
import { ReactElement, useRef, useState, useEffect } from 'react';
import RecordIcon from '../../res/icons/ic_record.svg'
import StopIcon from '../../res/icons/ic_stop.svg'

const CameraTile = (): ReactElement => {

    const [captureManager, setCaptureManager] = useState<CaptureManager>()
    const [recordToken, setRecordToken] = useState<string>()
    const [isRecording, setIsRecording] = useState<boolean>()
    const recordingVideoRef = useRef<HTMLVideoElement>(null);
    const [videoInputDevices, setVideoInputDevices] = useState<InputDevice[]>([])
    const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState<InputDevice>()

    const [isReady, setIsReady] = useState<boolean>(false)

    useEffect(() => {
        async function getData() {
            try {
                setVideoInputDevices(await getVideoInputDevicesAsync());
            } finally {
                setIsReady(true);
            }
        }
        setCaptureManager(new CaptureManager());
        getData();
    }, []);

    useEffect(() => {
        onChangeHandler();
    }, [videoInputDevices]);

    useEffect(() => {
        async function fetchStream() {
            try {
                await startStreamAsync();
            } catch (e) {
                console.error(e);
            }
        };

        if (!selectedVideoInputDevice) {
            console.log('any message');
            return;
        }

        fetchStream();
    }, [selectedVideoInputDevice]);

    const onChangeHandler = () => {
        const selectedInputDevice = videoInputDevices[0];

        console.log('selectedInputDevice', videoInputDevices);


        if (!selectedInputDevice) {
            return;
        }

        setSelectedVideoInputDevice(selectedInputDevice);
    }

    const startStreamAsync = async () => {


        const videoConstraints: MediaStreamConstraints = {
            video: {
                deviceId: selectedVideoInputDevice?.id
            }
        }

        let videoStream = new MediaStream();

        try {
            videoStream = await window.navigator.mediaDevices.getUserMedia(videoConstraints);
        } catch (error) {
            console.error("Error acquiring camera source:", error);
        }

        if (!recordingVideoRef.current) {
            return;
        }

        recordingVideoRef.current.srcObject = videoStream;
    }

    const startRecordingAsync = async () => {

        const fileHandle = await window.showSaveFilePicker({
            suggestedName: 'myVideo.mp4',
            types: [{
                description: 'Video File',
                accept: { 'video/mp4': ['.mp4'] }
            }],
        });

        if (!selectedVideoInputDevice?.capabilities) {
            console.log('line 86', selectedVideoInputDevice);
            return;
        }
        let selectedVideoDeviceOperatingPoint = getVideoDeviceOperatingPoints(selectedVideoInputDevice?.capabilities)[0];

        const getFrameRateConstraints = (): ConstrainDoubleRange => {
            if (selectedVideoDeviceOperatingPoint?.frameRate.exact) {
                return { exact: selectedVideoDeviceOperatingPoint.frameRate.value };
            }

            return { ideal: selectedVideoDeviceOperatingPoint?.frameRate.value };
        }

        const videoConstraints: MediaStreamConstraints = {
            video: {
                deviceId: selectedVideoInputDevice?.id,
                width: selectedVideoDeviceOperatingPoint?.resolution?.width ?? 0,
                height: selectedVideoDeviceOperatingPoint?.resolution?.height ?? 0,
                frameRate: getFrameRateConstraints()
            }
        }

        console.log(videoConstraints);
        let videoStream = new MediaStream();
        try {
            videoStream = await window.navigator.mediaDevices.getUserMedia(videoConstraints);
        } catch (error) {
            console.error("Error acquiring camera source:", error);
        }

        let bit = getSuggestedBitRates();

        let valResult = await getValidatedVideoCodecConfigurationsAsync(selectedVideoDeviceOperatingPoint.resolution, bit[0], selectedVideoDeviceOperatingPoint.frameRate, false);

        console.log(valResult);

        const recToken = await captureManager?.startRecordVideoAsync(fileHandle,
            videoStream,
            valResult[0]?.codecConfig as VideoEncoderConfig
        )

        setRecordToken(recToken);

        setIsRecording(true);
    }

    const stopRecordingAsync = async () => {
        await captureManager?.stopRecordingAsync(recordToken ?? "");

        setIsRecording(false);

        // @ts-ignore
        recordingVideoRef.current = null;

        return;
    }

    return (
        isReady ?
            <div className='cameraTile'>
                <video autoPlay ref={recordingVideoRef} className='video' />
                <div className='tileGrid'>
                    <div className='tileLabels'>
                        <label>{selectedVideoInputDevice != null ? selectedVideoInputDevice.name : "Video Camera"}</label>
                        <label>{selectedVideoInputDevice?.capabilities.width?.max?.toString()}x{selectedVideoInputDevice?.capabilities.height?.max?.toString()}p, {selectedVideoInputDevice?.capabilities.frameRate?.max?.toString()}  fps</label>
                    </div>
                    <div className='tileButtons'>
                        {isRecording ? <button onClick={stopRecordingAsync} className='stop'>
                            <img src={StopIcon} /> Stop
                        </button> : <button onClick={startRecordingAsync} className='rec'>
                            <img src={RecordIcon} />Rec
                        </button>}
                    </div>
                </div>
            </div> :
            <div />
    );
};

export default CameraTile;
