import { Layouts } from '../components/layouts/layout';
import AddIcon from '../res/icons/ic_add.svg'

import '../assets/styles/pages/capture.scss';
import { ReactElement, useState} from 'react';

export const CapturePage = (): ReactElement => {

  const [isVisible, setIsVisible] = useState(false);

  const onClick = () => setIsVisible(!isVisible);

  return (
    <Layouts>
        <div className="center">
          <div className="welcome">
          <h1>Capture Sources</h1>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</p>
            <button className="addsrc" onClick={onClick}>
              <img src={AddIcon}/>
              <label>Add Capture Source...</label>
            </button>
          </div>
        </div>
    </Layouts>
  );
};
