import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/home';
import {CapturePage } from './pages/capture';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="capture" element={<CapturePage />}/>
      </Routes>
    </BrowserRouter>
  );
};
